import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useRequestData, usePrefs, useLabels } from 'wsm-common-data';
import { useSelector } from 'react-redux';
import Form from './Form';
import SearchInput from './SearchInput';
import ButtonContainer from './ButtonContainer';
import SpeechButton from './SpeechButton';
import SubmitButton from './SubmitButton';
import generateUID from '../util/generateUID';

const InputDisplayButton = ({ openModal, speechRecognitionWrapper, type }) => {
	const labels = useLabels();
	const { searchPlaceholderLabel } = usePrefs();
	const { windowId } = useRequestData();
	const inputWrapperRef = useRef();

	const focusInputDisplayButton = (event) => {
		event.preventDefault();
		inputWrapperRef.current.focus();
	};
	const { pageAlias } = useSelector((state) => state.widgetData);

	const inputId = generateUID(`${pageAlias}_${windowId}`);
	return (
		<div
			ref={inputWrapperRef}
			role="button"
			aria-label={labels.get('SEARCH_THE_WHOLE_SITE')}
			tabIndex="0"
			onClick={openModal}
			data-type={type}
			onKeyDown={(event) => {
				const { ctrlKey, metaKey, altKey, key, keyCode } = event;

				if (!ctrlKey && !metaKey && !altKey) {
					const isEnter = key === 'Enter' || keyCode === 13;
					const isSpacebar = key === ' ' || keyCode === 32;
					const isNumber =
						/^[0-9]$/.test(key) || (keyCode > 47 && keyCode < 58);
					const isLetter =
						/^[a-zA-Z]$/.test(key) ||
						(keyCode > 64 && keyCode < 91);

					if (isEnter || isSpacebar || isNumber || isLetter) {
						if (isSpacebar) {
							event.preventDefault();
						}
						openModal();
					}
				}
			}}
		>
			{/* The form element below is aria-hidden because it and all its children
				(the input, the speech button, and the submit button) are present solely for visual display.
			*/}
			<Form isAriaHidden>
				<SearchInput
					inputId={inputId}
					onFocus={focusInputDisplayButton}
					placeholder={labels.get(searchPlaceholderLabel)}
					isInteractive={false}
				/>
				<ButtonContainer>
					{/* 
						React issue:
						without the containing spans, react was replacing the speech button
						with a duplicate submit button
					*/}
					<span>
						<SpeechButton
							speechRecognitionWrapper={speechRecognitionWrapper}
							isInteractive={false}
						/>
					</span>
					<span className="d-flex">
						<SubmitButton isInteractive={false} />
					</span>
				</ButtonContainer>
			</Form>
		</div>
	);
};

InputDisplayButton.propTypes = {
	openModal: PropTypes.func.isRequired,
	speechRecognitionWrapper: PropTypes.shape({}),
	type: PropTypes.string
};

export default InputDisplayButton;
