import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';

const VehicleCard = ({
	href,
	imageUrl,
	title,
	price,
	odometer,
	odometerUnit,
	condition,
	imageTitle,
	imageAlt,
	handleLinkClick
}) => {
	const labels = useLabels();

	const optionalImageAttributes = imageTitle ? { title: imageTitle } : {};
	const displayOdometer = odometer || '0';
	const displayOdometerUnit =
		odometer === '1' ? odometerUnit.slice(0, -1) : odometerUnit;
	const handleClick = () => {
		handleLinkClick('vehicleLink', '', '', '');
	};

	return (
		<a
			href={href}
			className="d-flex border-bottom vehicle-card-horizontal vehicle-card-mini modern-vehicle-card list-group-item"
			aria-label={`${labels.get('VIEW_DETAILS_ABOUT')} ${title}`}
			onClick={handleClick}
		>
			<div className="vehicle-card-media-container flex-grow-0 order-2">
				<img
					src={`${imageUrl}?impolicy=downsize&w=130`}
					alt={imageAlt || title}
					{...optionalImageAttributes}
				/>
			</div>
			<div className="vehicle-card-details-container order-1 ddc-font-size-small p-1 line-height-condensed">
				<p className="mt-0 mb-0 ddc-font-size-xsmall opacity-75">
					{condition}
				</p>
				<p className="font-weight-normal mt-0 mb-0">{title}</p>
				<p className="font-weight-bold mt-0 mb-0">{price}</p>
				<p className="mt-0 mb-0 opacity-75">{`${displayOdometer} ${displayOdometerUnit}`}</p>
			</div>
		</a>
	);
};
VehicleCard.propTypes = {
	href: PropTypes.string.isRequired,
	imageUrl: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	price: PropTypes.string.isRequired,
	odometer: PropTypes.string.isRequired,
	odometerUnit: PropTypes.string.isRequired,
	condition: PropTypes.string.isRequired,
	imageTitle: PropTypes.string,
	imageAlt: PropTypes.string,
	handleLinkClick: PropTypes.func
};
VehicleCard.defaultProps = {
	imageTitle: '',
	imageAlt: ''
};
export default VehicleCard;
