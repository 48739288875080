const transformInventoryResults = (
	results,
	searchTerm,
	{ allInventoryPath, newInventoryPath, usedInventoryPath }
) => {
	let transformedResults = [];
	if (results.length > 1 || (results.length === 1 && results[0].count)) {
		transformedResults = results.map(({ suggestion, count, facetInfo }) => {
			let path = allInventoryPath;

			if (
				facetInfo &&
				facetInfo.compositeType &&
				facetInfo.compositeType === 'new'
			) {
				path = newInventoryPath;
			} else if (
				facetInfo &&
				facetInfo.compositeType &&
				facetInfo.compositeType === 'used'
			) {
				path = usedInventoryPath;
			}

			return {
				title: suggestion,
				count,
				facets: Object.keys(facetInfo).join(','),
				path: facetInfo
					? `${path}?${Object.keys(facetInfo)
							.map(
								(key) =>
									`${encodeURIComponent(
										key
									)}=${encodeURIComponent(facetInfo[key])}`
							)
							.join('&')}`
					: allInventoryPath
			};
		});
	}
	return transformedResults;
};
export default transformInventoryResults;
