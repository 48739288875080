import React from 'react';
import PropTypes from 'prop-types';

const Form = ({ children, onSubmit, isAriaHidden, isModal }) => {
	return (
		<form
			className="form-control spacing-reset d-flex h-auto"
			autoComplete="off"
			onSubmit={onSubmit}
			aria-hidden={isAriaHidden}
			data-modal={isModal}
			data-form-tracking-id="SITEWIDE_SEARCH"
			data-form-tracking-type="typedSearch"
		>
			{children}
		</form>
	);
};

Form.propTypes = {
	children: PropTypes.node,
	onSubmit: PropTypes.func,
	isAriaHidden: PropTypes.bool,
	isModal: PropTypes.bool
};

export default Form;
