import React from 'react';
import PropTypes from 'prop-types';
import { useRequestData, useLabels, usePrefs } from 'wsm-common-data';
import { Modal } from 'react-bootstrap';
import { g } from '../global-constants';

const SearchModal = ({
	isOpen,
	onClose,
	inputRef = React.createRef(),
	children = []
}) => {
	const { deviceType } = useRequestData();
	const isMobile = deviceType === g.MOBILE;
	const labels = useLabels();
	const { searchPlaceholderLabel } = usePrefs();

	const onOpen = () => {
		if (inputRef && inputRef.current) {
			inputRef.current.focus();
		}
	};

	return (
		<Modal
			show={isOpen}
			onHide={onClose}
			onShow={onOpen}
			className={isMobile ? 'dock-full no-slide' : ''}
			dialogClassName={!isMobile ? 'modal-lg' : ''}
			aria-labelledby="site-text-search-modal-title"
		>
			<Modal.Header className="border-bottom-0 pb-0 d-flex justify-content-end">
				<Modal.Title
					id="site-text-search-modal-title"
					className="sr-only"
				>
					{labels.get(searchPlaceholderLabel)}
				</Modal.Title>
				{!isMobile && (
					<button
						type="button"
						aria-label={labels.get('CLOSE')}
						className="btn-unstyled text-link-muted py-3 px-4"
						onClick={() => onClose(g.CLOSE_BUTTON)}
					>
						<i
							className="ddc-icon ddc-icon-remove2 ddc-icon-size-large"
							aria-hidden="true"
						/>
					</button>
				)}
			</Modal.Header>
			<Modal.Body className={!isMobile ? 'px-7 pb-8 pt-0' : 'pt-0'}>
				{children}
			</Modal.Body>
		</Modal>
	);
};

SearchModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	inputRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.element),
		PropTypes.element,
		PropTypes.string
	]),
	speechRecognitionWrapper: PropTypes.shape({
		isListening: PropTypes.func,
		onStartListening: PropTypes.func,
		onStopListening: PropTypes.func,
		onResult: PropTypes.func,
		toggleListening: PropTypes.func
	})
};

export default SearchModal;
