const hasResults = (
	pageResults,
	suggestions,
	vehicles,
	richContentToDisplay
) => {
	return (
		pageResults?.length > 0 ||
		suggestions?.length > 0 ||
		vehicles?.length > 0 ||
		richContentToDisplay.length > 0
	);
};

export default hasResults;
