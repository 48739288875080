import logger from 'ws-scripts/modules/logger';
import { hysteriFetch, wrappers } from 'hysterics';

const pagesClientService = hysteriFetch('pagesClientService', {
	timeout: {
		timeout: 1900
	},
	[wrappers.retry.contextKey]: {
		retries: 1
	}
});

export default async function fetchPages({
	searchTerm,
	siteId,
	locale = 'en_US',
	widgetSearchServiceUri = ''
}) {
	try {
		const response = await pagesClientService(
			// proxy through the node server if on localhost or dev because the relative endpoint is not available
			`${widgetSearchServiceUri}/pageSearch/${siteId}/${locale}?q=${encodeURIComponent(
				searchTerm
			)}`
		);
		return response;
	} catch (error) {
		logger.error(
			`Error response from page search request: Error: ${error}`
		);
		return [];
	}
}
