import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';

const SearchLinks = ({
	type,
	showResults,
	data,
	iconClass,
	headingLabel,
	ariaLabel,
	handleLinkClick
}) => {
	const labels = useLabels();

	if (showResults === 'true' && data?.length > 0) {
		let keyCount = 0;
		return (
			<div
				data-testid={`modern-${type}-search-links`}
				role="region"
				aria-labelledby={`${type}-search-links-heading`}
			>
				<h3 id={`${type}-search-links-heading`} className="sr-only">
					{headingLabel}
				</h3>
				<ul className="list-group mb-3 mt-3">
					{data.slice(0, 5).map((item) => {
						keyCount += 1;
						const handleClick = () => {
							let elementCTA = '';
							let linkType = '';
							if (type === 'inventory') {
								linkType = 'inventoryLink';
								elementCTA = item.facets || '';
							} else if (type === 'page') {
								linkType = 'pageLink';
								elementCTA = item.title;
							}
							handleLinkClick(
								linkType,
								elementCTA,
								item.title,
								''
							);
						};
						return (
							<li key={keyCount} className="line-height-reset">
								<a
									className="d-flex align-items-center list-group-item"
									href={item.path}
									// eslint-disable-next-line max-len
									aria-label={`${labels.get('VISIT')} ${
										item.title
									} ${ariaLabel}`}
									onClick={handleClick}
								>
									<i
										className={`ddc-icon ${iconClass} ddc-icon-size-large mr-3`}
										aria-hidden="true"
									/>
									<span className="ddc-font-size-base">
										{item.title}
									</span>
								</a>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}
	return null;
};

SearchLinks.propTypes = {
	type: PropTypes.oneOf(['inventory', 'page']).isRequired,
	data: PropTypes.arrayOf(PropTypes.shape({})),
	showResults: PropTypes.string,
	iconClass: PropTypes.string,
	headingLabel: PropTypes.string,
	ariaLabel: PropTypes.string,
	handleLinkClick: PropTypes.func
};

export default SearchLinks;
