import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { setClassNames } from 'ddc-classnames-js';

const SearchInput = ({
	inputRef = React.createRef(),
	triggerTrackingEvent = () => {},
	inputId,
	value = '',
	placeholder = '',
	placeholderListening = '',
	onInput = () => {},
	onArrowKey = () => {},
	onFocus = () => {},
	speechRecognitionWrapper = {
		isListening: () => false,
		onStartListening: () => {},
		onStopListening: () => {}
	},
	isInteractive = true
}) => {
	const { isListening, onStartListening, onStopListening } =
		speechRecognitionWrapper;
	const [listening, setListening] = useState(isListening());
	const [interacted, setInteracted] = useState(false);

	const handleKeyUp = (event) => {
		/* The form (parent component) uses the up and down keys to change the active result */
		if (event.keyCode === 38) {
			onArrowKey('up');
			event.preventDefault();
		} else if (event.keyCode === 40) {
			onArrowKey('down');
			event.preventDefault();
		}
	};

	const handleKeyDown = (event) => {
		if (!interacted) {
			setInteracted(true);
			triggerTrackingEvent({
				event: 'widgetUserEvent',
				element: 'search box',
				elementCTA: undefined,
				action: 'clicked',
				result: 'search modal initiated',
				widgetState: 'default',
				fieldValue: undefined,
				formId: undefined,
				value: undefined,
				nonInteractive: false
			});
		}
		if (event.keyCode === 38 || event.keyCode === 40) {
			event.preventDefault(); // Prevent the cursor from moving within the input
		}
	};

	const handleInput = (event) => {
		if (event.target.value !== value) {
			onInput(event.target.value);
		}
	};

	useEffect(() => {
		onStartListening(() => {
			setListening(true);
		});

		onStopListening(() => {
			setListening(false);
		});
	}, [onStartListening, onStopListening]);

	const classNames = [
		'form-control',
		'site-text-search-input',
		'font-size-ios-zoom-override',
		'form-control-transparent',
		!isInteractive && 'pointer-events-none'
	];

	const placeholderText = listening ? placeholderListening : placeholder;

	const label = (
		<label htmlFor={inputId} className="sr-only">
			{placeholderText}
		</label>
	);

	return (
		<React.Fragment>
			{inputId && label}
			<input
				ref={inputRef}
				onKeyUp={!isInteractive ? () => {} : handleKeyUp}
				onKeyDown={!isInteractive ? () => {} : handleKeyDown}
				onChange={!isInteractive ? () => {} : handleInput}
				onFocus={onFocus}
				className={setClassNames(classNames)}
				placeholder={placeholderText}
				value={value}
				id={inputId}
				tabIndex={!isInteractive ? -1 : null}
				name="search"
			/>
		</React.Fragment>
	);
};

SearchInput.propTypes = {
	inputId: PropTypes.string.isRequired,
	inputRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	triggerTrackingEvent: PropTypes.func,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	placeholderListening: PropTypes.string,
	onInput: PropTypes.func,
	onArrowKey: PropTypes.func,
	onFocus: PropTypes.func,
	speechRecognitionWrapper: PropTypes.shape({
		isListening: PropTypes.func,
		onStartListening: PropTypes.func,
		onStopListening: PropTypes.func
	}),
	isInteractive: PropTypes.bool
};

export default SearchInput;
