import { hysteriFetch, wrappers } from 'hysterics';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';

let initialLoad = true;

export default async function fetchInventory({
	searchTerm,
	siteId,
	locale = 'en_US',
	widgetSearchServiceUri = '',
	configTypes,
	resultsPageAlias = '',
	inventoryFetchTimeout
}) {
	// HYSTERIFETCH USAGE HERE:
	// On server-side, hysterifetch should only be invoked once per service (in module scope)
	// This hysterifetch invocation is on client, every page refresh is a new instance
	// This is so we can pass a timeout value from feature flag
	const inventoryClientService = hysteriFetch('inventoryClientService', {
		[wrappers.timeout.contextKey]: {
			timeout: inventoryFetchTimeout
		},
		[wrappers.retry.contextKey]: {
			retries: 1
		}
	});

	const response = await inventoryClientService(
		`${widgetSearchServiceUri}/inventorySearch/${siteId}/${locale}?q=${encodeURIComponent(
			searchTerm
		)}
		&ct=${configTypes}&shouldReturnCardsAlso=true`
	);

	// TODO: Temporary code.
	// There are instances where a user gets navigated to the catch-all search results page
	// rather than the SRP as a result of typing too fast and hitting return.

	// Logging to NR when the following conditions are met:
	// When the user is redirected to the search results page
	// AND only once on initial load
	// AND when the document.referrer happens to be the same domain
	if (
		window.location.href.includes(resultsPageAlias) &&
		initialLoad &&
		document.referrer.startsWith(window.location.origin)
	) {
		const suggestions = (response && response.suggestions) || [];
		const { count } = suggestions[0] || 0;
		if (count > 0) {
			setNewRelicCustomAttribute(
				'wsSiteTextSearch-navigatedToSearchPageByError',
				'true'
			);
		}
		initialLoad = false;
	}

	return response;
}
