import { trackEvent } from 'ddc-track-event';

export default class TrackingEvent {
	constructor(windowId, widgetName) {
		if (!windowId) {
			throw new Error('windowId must be passed in constructor');
		}
		this.triggerTrackingEvent = this.triggerTrackingEvent.bind(this);
		this.windowId = windowId;
		this.widgetName = widgetName;
	}

	triggerTrackingEvent(trackingArgs) {
		trackEvent(this.widgetName, this.windowId, trackingArgs);
	}
}
