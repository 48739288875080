import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { useLabels, usePrefs, useRequestData } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { isBrowser } from 'ws-scripts/modules/environment';
import { g } from '../global-constants';

const SearchButton = ({ searchButtonRef = React.createRef(), onClick }) => {
	const {
		searchButtonLabel,
		searchButtonIcon,
		searchButtonIconSize,
		searchButtonStyle,
		display
	} = usePrefs();
	const useNavPortal = display === g.NAVIGATION;
	const labels = useLabels();
	let label = useNavPortal ? null : labels.get(searchButtonLabel);
	const title = labels.get('SEARCH_THE_WHOLE_SITE');
	const { deviceType } = useRequestData();
	const LinkElement = useNavPortal ? 'a' : 'button';

	const iconClasses = [
		'ddc-icon',
		`ddc-icon-${searchButtonIcon}`,
		searchButtonIconSize && `ddc-icon-size-${searchButtonIconSize}`,
		useNavPortal && 'ddc-nav-icon',
		label && 'mr-3'
	];

	const buttonClasses = [
		...(useNavPortal
			? ['ddc-nav-icon-link']
			: [
					'btn',
					`btn-${searchButtonStyle}`,
					'text-decoration-none',
					'btn-no-decoration'
			  ])
	];

	let navigationTarget;
	if (useNavPortal && isBrowser) {
		navigationTarget = document.querySelector(
			'.ws-site-text-search-navigation-target'
		);
		if (navigationTarget) {
			if (deviceType === g.MOBILE) {
				const velocityButton =
					navigationTarget.querySelector('.btn-placeholder');
				if (velocityButton) {
					// remove the velocity label to make way for the portal
					navigationTarget.removeChild(velocityButton);
				}
				// on mobile, See if the 'data-search-label' has a value.
				label =
					navigationTarget.getAttribute('data-search-label') || null;
				const navButtonClasses = [
					'btn',
					'ddc-btn-nav',
					'btn-no-decoration',
					'border-left',
					'btn-search',
					'btn-default',
					'text-center'
				];
				buttonClasses.push(...navButtonClasses);
			}
		}
	}

	const button = (
		<LinkElement
			ref={searchButtonRef}
			onClick={onClick}
			onKeyDown={
				useNavPortal
					? (event) => {
							if (event.key === ' ' || event.keyCode === 32) {
								onClick(event);
							}
					  }
					: null
			}
			title={title}
			aria-label={label ? null : title}
			className={setClassNames(buttonClasses)}
			href={useNavPortal ? '#' : null}
			role={useNavPortal ? 'button' : null}
		>
			<i className={setClassNames(iconClasses)} aria-hidden="true" />
			{deviceType === g.MOBILE && label ? (
				<span className="ddc-btn-text">{label}</span>
			) : (
				label
			)}
		</LinkElement>
	);

	const buttonInNavigation = navigationTarget
		? ReactDOM.createPortal(button, navigationTarget)
		: null;

	return useNavPortal ? buttonInNavigation : button;
};

SearchButton.propTypes = {
	searchButtonRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	onClick: PropTypes.func.isRequired
};

export default SearchButton;
