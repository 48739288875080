const shouldShowRecentSearches = (
	searchTerm,
	numberOfCharactersForFirstSearchNumber,
	hasResults,
	hasEmptyResults,
	recentSearches
) => {
	return (
		(!searchTerm ||
			searchTerm.length < numberOfCharactersForFirstSearchNumber ||
			(!hasResults && !hasEmptyResults)) &&
		recentSearches?.length > 0
	);
};

export default shouldShowRecentSearches;
