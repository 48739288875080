import { useEffect, useState } from 'react';

const useFocusTrap = (
	containerRefs,
	dropDownRef,
	flyOutRef,
	inputElementRef,
	openButtonRef,
	isOpen,
	closeModal
) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		if (!isOpen) return;
		const handleKeyDown = (event) => {
			const focusableElements = containerRefs.flatMap((ref) =>
				Array.from(
					ref.current?.querySelectorAll(
						'a, button, input:not([type="hidden"]), [tabindex]:not([tabindex="-1"])'
					) || []
				)
			);
			if (focusableElements.length === 0) {
				return;
			}

			if (event.key === 'Tab') {
				event.preventDefault();
				let newIndex = currentIndex;

				if (event.shiftKey) {
					// Shift + Tab
					newIndex =
						(currentIndex - 1 + focusableElements.length) %
						focusableElements.length;
				} else {
					// Tab
					newIndex = (currentIndex + 1) % focusableElements.length;
				}
				if (dropDownRef) {
					const isAtBoundary =
						(currentIndex === focusableElements.length - 1 &&
							!event.shiftKey) ||
						(newIndex === 0 && event.shiftKey);

					if (isAtBoundary) {
						if (
							dropDownRef.current ||
							(!dropDownRef.current && !flyOutRef)
						) {
							newIndex = -1;
						}
					}
				}
				if (newIndex === -1) {
					if (inputElementRef && inputElementRef.current) {
						inputElementRef.current.focus();
						// Place cursor at the end of empty input
						inputElementRef.current.setSelectionRange(
							inputElementRef.current.value.length,
							inputElementRef.current.value.length
						);
					}
					closeModal();
				} else {
					focusableElements[newIndex].focus();
					setCurrentIndex(newIndex);
				}
			} else if (event.key === 'Escape') {
				if (!dropDownRef) {
					closeModal();
				}
				if (inputElementRef && inputElementRef.current) {
					inputElementRef.current.focus();
					// Place cursor at the end of empty input
					inputElementRef.current.setSelectionRange(
						inputElementRef.current.value.length,
						inputElementRef.current.value.length
					);
				} else if (openButtonRef && openButtonRef.current) {
					openButtonRef.current.focus();
				}
				if (dropDownRef && dropDownRef.current) {
					closeModal();
				}
			}
		};

		const containers = containerRefs
			.map((ref) => ref.current)
			.filter((container) => container !== null);

		containers.forEach((container) => {
			container.addEventListener('keydown', handleKeyDown);
		});

		// Cleanup function
		// eslint-disable-next-line consistent-return
		return () => {
			containers.forEach((container) => {
				container.removeEventListener('keydown', handleKeyDown);
			});
		};
	}, [containerRefs, isOpen, currentIndex]);

	return null;
};

export default useFocusTrap;
