import React, { useEffect, useState } from 'react';
import { useLabels, usePrefs } from 'wsm-common-data';
import PropTypes from 'prop-types';
import ContactLinksList from './ContactLinksList';
import DepartmentHoursTabs from './DepartmentHoursTabs';
import AddressCard from './AddressCard';
import generateUID from '../../../util/modern/generateUID';

const RichContent = ({
	addInfo,
	richContentToDisplay,
	pageAlias,
	windowId,
	handleLinkClick
}) => {
	const labels = useLabels();
	const {
		addressHeadingLabel,
		addressClasses,
		addressGetDirectionsLabel,
		hoursTabsHeaderLabel
	} = usePrefs();
	const [contactLinksAriaLabelledById, setContactLinksAriaLabelledById] =
		useState('');
	const [addressAriaLabelledById, setAddressAriaLabelledById] = useState('');
	const [hoursTabsAriaLabelledById, setHoursTabsAriaLabelledById] =
		useState('');
	const [hoursTabsId, setHoursTabsId] = useState('');

	useEffect(() => {
		// This effect only runs once to setup specific data for this component
		// Generate unique ids
		setContactLinksAriaLabelledById(
			generateUID(`${pageAlias}_${windowId}`)
		);
		setAddressAriaLabelledById(generateUID(`${pageAlias}_${windowId}`));
		setHoursTabsAriaLabelledById(generateUID(`${pageAlias}_${windowId}`));
		setHoursTabsId(generateUID(`${pageAlias}_${windowId}`));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	// Contact Links

	const renderContactLinksList = () => {
		if (
			addInfo &&
			addInfo.phoneNumbers &&
			richContentToDisplay.some(
				(item) =>
					[
						'phone-sales',
						'phone-service',
						'phone-parts',
						'phone-commercial',
						'email'
					].indexOf(item) > -1
			)
		) {
			const { phoneNumbers, email = '' } = addInfo;
			return (
				<ContactLinksList
					phoneNumbers={phoneNumbers}
					email={email}
					richContentToDisplay={richContentToDisplay}
					ariaLabelledById={contactLinksAriaLabelledById}
					handleLinkClick={handleLinkClick}
				/>
			);
		}
		return null;
	};

	// Address
	const renderAddress = () => {
		const hideLabel = richContentToDisplay.some(
			(item) =>
				[
					'email',
					'phone-sales',
					'phone-service',
					'phone-parts',
					'phone-commercial'
				].indexOf(item) > -1
		);
		if (addInfo && richContentToDisplay.includes('address')) {
			const {
				dealerInfo: { name, address1, city, state, postalCode },
				mapLink
			} = addInfo;
			return (
				<AddressCard
					name={name}
					address1={address1}
					city={city}
					state={state}
					postalCode={postalCode}
					getDirectionsLabel={labels.get(addressGetDirectionsLabel)}
					getDirectionsUrl={mapLink}
					label={!hideLabel ? labels.get(addressHeadingLabel) : ''}
					ariaLabelledById={addressAriaLabelledById}
					addressClasses={addressClasses}
					handleLinkClick={handleLinkClick}
				/>
			);
		}
		return null;
	};

	// Hours
	const renderHours = () => {
		if (
			addInfo &&
			addInfo.hours &&
			richContentToDisplay.some(
				(item) =>
					[
						'hours-sales',
						'hours-service',
						'hours-parts',
						'hours-commercial'
					].indexOf(item) > -1
			)
		) {
			const { hours } = addInfo;
			const departments = richContentToDisplay
				.filter((richContentName) =>
					richContentName.startsWith('hours')
				)
				.map((richContentName) => richContentName.split('-')[1]);
			return (
				<DepartmentHoursTabs
					departments={departments}
					departmentHours={hours}
					label={labels.get(hoursTabsHeaderLabel)}
					labels={labels}
					ariaLabelledById={hoursTabsAriaLabelledById}
					tabId={hoursTabsId}
					handleLinkClick={handleLinkClick}
				/>
			);
		}
		return null;
	};

	const renderFunctions = {
		'phone-sales': renderContactLinksList,
		'phone-service': renderContactLinksList,
		'phone-parts': renderContactLinksList,
		'phone-commercial': renderContactLinksList,
		email: renderContactLinksList,
		address: renderAddress,
		'hours-sales': renderHours,
		'hours-service': renderHours,
		'hours-parts': renderHours,
		'hours-commercial': renderHours
	};

	const renderedComponents = new Set();

	return (
		<div
			className="modern-search-richcontent"
			data-testid="modern-search-richcontent"
		>
			{richContentToDisplay.map((item) => {
				const renderFunction = renderFunctions[item];
				if (renderFunction && !renderedComponents.has(renderFunction)) {
					renderedComponents.add(renderFunction);
					return renderFunction();
				}
				return null;
			})}
		</div>
	);
};
RichContent.propTypes = {
	addInfo: PropTypes.shape(),
	richContentToDisplay: PropTypes.arrayOf(PropTypes.string),
	pageAlias: PropTypes.string,
	windowId: PropTypes.string,
	handleLinkClick: PropTypes.func
};

export default RichContent;
