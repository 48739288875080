import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels, usePrefs } from 'wsm-common-data';
import RichContent from './richContent/RichContent';
import useFocusTrap from '../../hooks/modern/useFocusTrap';
import VehicleCards from './VehicleCards';
import SearchLinks from './SearchLinks';
import NoResults from './NoResults';
import RecentSearches from './RecentSearches';
import shouldShowRecentSearches from '../../util/modern/shouldShowRecentSearches';
import hasEmptyResults from '../../util/modern/hasEmptyResults';
import hasResults from '../../util/modern/hasResults';
import ContentColumnizer from './ContentColumnizer';
import useAriaLive from '../../hooks/modern/useAriaLive';

const DropDown = ({
	dropDownRef = React.createRef(),
	inputRef,
	inputElementRef,
	isOpen,
	recentSearches,
	dropDownPosition,
	inputWidth,
	onRemove,
	handleClearHistory,
	onReorder,
	inventoryResults,
	pageResults,
	searchTerm,
	numberOfCharactersForFirstSearchNumber,
	handleChipClick,
	handleLinkClick,
	addInfo,
	richContentToDisplay,
	pageAlias,
	windowId,
	closeModal
}) => {
	const { suggestions = undefined, vehicles = undefined } =
		inventoryResults || {};
	const labels = useLabels();
	const prefs = usePrefs();
	const chipAriaLabel = labels.get('SEARCH');
	const removeAriaLabel = labels.get('REMOVE');
	const isSearchTermLongEnough =
		searchTerm?.length >= numberOfCharactersForFirstSearchNumber;

	const hasResultsLocal = hasResults(
		pageResults,
		suggestions,
		vehicles,
		richContentToDisplay
	);

	const hasEmptyResultsLocal = hasEmptyResults(
		pageResults,
		suggestions,
		vehicles
	);

	const announceText = useAriaLive(
		isSearchTermLongEnough,
		hasResultsLocal,
		hasEmptyResultsLocal
	);

	useFocusTrap(
		[inputRef, dropDownRef],
		dropDownRef,
		null,
		inputElementRef,
		null,
		isOpen,
		closeModal
	);

	const classNames = [
		'modern-search-dropdown',
		'box',
		'p-4',
		!isOpen && 'hidden'
	];

	if (
		!recentSearches?.length &&
		(!searchTerm ||
			!isSearchTermLongEnough ||
			(pageResults === undefined &&
				suggestions === undefined &&
				vehicles === undefined))
	) {
		return null;
	}

	const dropDownContent = (
		<div
			ref={dropDownRef}
			className={setClassNames(classNames)}
			data-testid="modern-input-dropdown"
			role="dialog"
			aria-labelledby={
				searchTerm ? 'results-heading' : 'recent-searches-heading'
			}
			aria-modal="true"
			style={{
				position: 'absolute',
				top: dropDownPosition.top,
				left: dropDownPosition.left,
				width: inputWidth
			}}
		>
			<div aria-live="assertive" className="sr-only">
				{announceText}
			</div>
			{isSearchTermLongEnough &&
				!hasResultsLocal &&
				hasEmptyResultsLocal && <NoResults searchTerm={searchTerm} />}

			{shouldShowRecentSearches(
				searchTerm,
				numberOfCharactersForFirstSearchNumber,
				hasResultsLocal,
				hasEmptyResultsLocal,
				recentSearches
			) && (
				<RecentSearches
					recentSearches={recentSearches}
					handleClearHistory={handleClearHistory}
					chipAriaLabel={chipAriaLabel}
					removeAriaLabel={removeAriaLabel}
					onRemove={onRemove}
					onReorder={onReorder}
					handleChipClick={handleChipClick}
				/>
			)}

			{isSearchTermLongEnough && hasResultsLocal && (
				<div>
					<h3 id="results-heading" className="sr-only">
						{labels.get('SEARCH_RESULTS')}
					</h3>
					<ContentColumnizer
						inputWidth={inputWidth}
						vehicles={vehicles?.length > 0}
					>
						{vehicles?.length > 0 ? (
							<VehicleCards
								vehicles={vehicles}
								handleLinkClick={handleLinkClick}
							/>
						) : null}
						{suggestions?.length > 0 ? (
							<SearchLinks
								type="inventory"
								showResults={prefs.showInventoryResults}
								data={suggestions}
								iconClass="ddc-icon-single-vehicle"
								headingLabel={labels.get(
									'INVENTORY_SEARCH_RESULTS'
								)}
								ariaLabel={labels.get(
									'INVENTORY_SEARCH_RESULTS'
								)}
								handleLinkClick={handleLinkClick}
							/>
						) : null}
						{pageResults?.length > 0 ? (
							<SearchLinks
								type="page"
								showResults={prefs.showPageSearchResults}
								data={pageResults}
								iconClass="ddc-icon-arrow-circle-right"
								headingLabel={labels.get('PAGE_SEARCH_RESULTS')}
								ariaLabel={labels.get('PAGE_SEARCH_RESULTS')}
								handleLinkClick={handleLinkClick}
							/>
						) : null}
						{richContentToDisplay?.length > 0 ? (
							<RichContent
								addInfo={addInfo}
								richContentToDisplay={richContentToDisplay}
								pageAlias={pageAlias}
								windowId={windowId}
								handleLinkClick={handleLinkClick}
							/>
						) : null}
					</ContentColumnizer>
				</div>
			)}
		</div>
	);
	return ReactDOM.createPortal(dropDownContent, document.body);
};
DropDown.propTypes = {
	dropDownRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	inputRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	inputElementRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	isOpen: PropTypes.bool.isRequired,
	recentSearches: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			id: PropTypes.string
		})
	),
	dropDownPosition: PropTypes.shape({
		top: PropTypes.number.isRequired,
		left: PropTypes.number.isRequired
	}).isRequired,
	inputWidth: PropTypes.number.isRequired,
	onRemove: PropTypes.func,
	handleClearHistory: PropTypes.func,
	onReorder: PropTypes.func,
	inventoryResults: PropTypes.shape({}),
	pageResults: PropTypes.arrayOf(PropTypes.shape({})),
	searchTerm: PropTypes.string,
	numberOfCharactersForFirstSearchNumber: PropTypes.number,
	handleChipClick: PropTypes.func,
	handleLinkClick: PropTypes.func,
	addInfo: PropTypes.shape(),
	richContentToDisplay: PropTypes.arrayOf(PropTypes.string),
	pageAlias: PropTypes.string,
	windowId: PropTypes.string,
	closeModal: PropTypes.func
};

export default DropDown;
