import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels } from 'wsm-common-data';

const SpeechButton = ({
	speechRecognitionWrapper = {
		isListening: () => {},
		toggleListening: () => {},
		onStartListening: () => {},
		onStopListening: () => {},
		stopListening: () => {},
		speechRecognizerIsDefined: () => {}
	},
	isInteractive = true,
	triggerTrackingEvent = () => {},
	inputRef
}) => {
	const {
		isListening,
		toggleListening,
		onStartListening,
		onStopListening,
		stopListening
	} = speechRecognitionWrapper;
	const labels = useLabels();
	const [isActive, setIsActive] = useState(isListening());
	const [isHovered, setIsHovered] = useState(false);
	const isSpeechAPISupported =
		speechRecognitionWrapper?.speechRecognizerIsDefined?.() || false;
	useEffect(() => {
		onStartListening(() => {
			setIsActive(true);
		});

		onStopListening(() => {
			setIsActive(false);
		});

		return function cleanup() {
			stopListening();
		};
	}, [onStartListening, onStopListening, stopListening]);

	const onClick = () => {
		triggerTrackingEvent({
			element: 'voice search button',
			result: `${isActive ? 'stopped' : 'started'} voice search`
		});
		toggleListening();
		inputRef.current.focus();
	};

	if (isSpeechAPISupported) {
		return (
			<button
				type="button"
				onClick={inputRef && isInteractive ? onClick : null}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				tabIndex={isInteractive ? null : '-1'}
				aria-label={labels.get('VOICE_SEARCH')}
				className={setClassNames([
					'btn-voice-search',
					'h-100',
					'mx-3',
					'my-0',
					isActive && 'active',
					!isInteractive && 'pointer-events-none'
				])}
			>
				<i
					className={setClassNames([
						'ddc-icon',
						'icon-size-2',
						isHovered && isActive
							? 'ddc-icon-microphone-off'
							: 'ddc-icon-microphone'
					])}
					aria-hidden="true"
				/>
			</button>
		);
	} else {
		return null;
	}
};

SpeechButton.propTypes = {
	speechRecognitionWrapper: PropTypes.shape({
		isListening: PropTypes.func,
		onStartListening: PropTypes.func,
		onStopListening: PropTypes.func,
		toggleListening: PropTypes.func,
		stopListening: PropTypes.func,
		speechRecognizerIsDefined: PropTypes.func
	}),
	isInteractive: PropTypes.bool,
	triggerTrackingEvent: PropTypes.func,
	inputRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	])
};

export default SpeechButton;
