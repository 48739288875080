export default class SpeechRecognitionWrapper {
	constructor() {
		this.listening = false;
		this.speechRecognizerIsDefined =
			this.speechRecognizerIsDefined.bind(this);
		this.startListening = this.startListening.bind(this);
		this.stopListening = this.stopListening.bind(this);
		this.onResult = this.onResult.bind(this);
		this.onStartListening = this.onStartListening.bind(this);
		this.onStopListening = this.onStopListening.bind(this);
		this.toggleListening = this.toggleListening.bind(this);
		this.isListening = this.isListening.bind(this);
		this.getSpeechRecognizer = this.getSpeechRecognizer.bind(this);
		this.speechRecognizer = this.getSpeechRecognizer();
	}

	getSpeechRecognizer() {
		let speechRecognizer;
		if (
			typeof window !== 'undefined' &&
			(typeof window.SpeechRecognition !== 'undefined' ||
				typeof window.webkitSpeechRecognition !== 'undefined')
		) {
			const SpeechRecognition =
				window.SpeechRecognition || window.webkitSpeechRecognition;
			speechRecognizer = new SpeechRecognition();

			speechRecognizer.addEventListener('start', () => {
				this.listening = true;
			});

			speechRecognizer.addEventListener('end', () => {
				this.listening = false;
			});
		}

		return speechRecognizer;
	}

	speechRecognizerIsDefined() {
		return typeof this.speechRecognizer !== 'undefined';
	}

	startListening() {
		if (this.speechRecognizerIsDefined()) {
			this.speechRecognizer.start();
		}
	}

	stopListening() {
		if (this.speechRecognizerIsDefined()) {
			this.speechRecognizer.stop();
		}
	}

	onResult(callback) {
		if (this.speechRecognizerIsDefined()) {
			this.speechRecognizer.addEventListener('result', (event) => {
				/*
				[0] gets you the first speech input of this listening session
					(we are only listening until the first pause so there will only ever be one)
					the second [0] gets the best guess, becuase we are not using alternative "grammars" there will only ever be one.
					therefore [0][0] will always contain a phrase when this 'result' event is triggered,
					and [0][0] will be the only phase ever contained within the result.
				Resources: 	https://developer.mozilla.org/en-US/docs/Web/API/Web_Speech_API/Using_the_Web_Speech_API
										https://davidwalsh.name/speech-recognition
			*/
				callback(event.results[0][0].transcript);
			});
		}
	}

	onStartListening(callback) {
		if (this.speechRecognizerIsDefined()) {
			this.speechRecognizer.addEventListener('start', callback);
		}
	}

	onStopListening(callback) {
		if (this.speechRecognizerIsDefined()) {
			this.speechRecognizer.addEventListener('end', callback);
		}
	}

	toggleListening() {
		if (this.listening) {
			this.stopListening();
		} else {
			this.startListening();
		}
	}

	isListening() {
		return this.listening;
	}
}
