import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';

const EmailLink = ({ email, children, handleLinkClick }) => {
	const labels = useLabels();

	const emailAriaLabel = `${labels.get('SEND_AN_EMAIL_TO')} ${email}`;

	const handleClick = () => {
		handleLinkClick('richContentLink', children, '', '');
	};

	return (
		<li>
			<a
				href={`mailto:${email}`}
				target="_blank"
				rel="noopener noreferrer"
				className="btn btn-default btn-block"
				onClick={handleClick}
				aria-label={emailAriaLabel}
			>
				<i
					className="ddc-icon ddc-icon-email mr-3"
					aria-hidden="true"
				/>
				{children || email}
			</a>
		</li>
	);
};

EmailLink.propTypes = {
	email: PropTypes.string.isRequired,
	children: PropTypes.string,
	handleLinkClick: PropTypes.func
};
EmailLink.defaultProps = {
	children: '',
	handleLinkClick() {}
};
export default EmailLink;
