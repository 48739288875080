import React from 'react';
import PropTypes from 'prop-types';

const ButtonContainer = ({ children }) => {
	return <div className="d-inline-flex">{children}</div>;
};

ButtonContainer.propTypes = {
	children: PropTypes.node
};

export default ButtonContainer;
