import React from 'react';
import PropTypes from 'prop-types';
import { usePrefs } from 'wsm-common-data';
import { g } from '../../../global-constants';

const ResultsHeading = ({ label, ariaLabelledById }) => {
	const { display } = usePrefs();
	const labelAttributes = {
		id: ariaLabelledById,
		className: `ddc-font-size-xlarge ${
			display === g.EMBEDDED ? 'widget-heading' : ''
		}`.trim()
	};
	return <h3 {...labelAttributes}>{label}</h3>;
};

ResultsHeading.propTypes = {
	label: PropTypes.string.isRequired,
	ariaLabelledById: PropTypes.string.isRequired
};

export default ResultsHeading;
