import PropTypes from 'prop-types';
import React from 'react';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels } from 'wsm-common-data';

const SubmitButton = ({ isInteractive = true }) => {
	const labels = useLabels();

	return (
		<button
			type="submit"
			tabIndex={isInteractive ? null : '-1'}
			aria-label={labels.get('SUBMIT')}
			className={setClassNames([
				'text-link',
				'ml-3',
				'mr-4',
				'my-0',
				'text-decoration-none',
				!isInteractive && 'pointer-events-none'
			])}
		>
			<i
				className="ddc-icon ddc-icon-search icon-size-2"
				aria-hidden="true"
			/>
		</button>
	);
};

SubmitButton.propTypes = {
	isInteractive: PropTypes.bool
};

export default SubmitButton;
